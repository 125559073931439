<template>

	<container container_id="games" :collapse_on_mount="true"  :header_toggle="true">

		<template slot="container-head">
			
			<span>{{ games.length }} MATCHS</span> - 
			<span>FERMETURE : <em :class="{ red : ( close_hour != '00H00' ) }">{{ close_hour }}</em></span>

		</template>

		<template slot="container-body"><div class="games-list">

			<div v-for="( game, key ) in vue_games" v-bind:key="key" :data-game_id="game.game_id" class="game">
				<span class="logo"><img :src="require( '@/assets/img/logos/' + game.vTeam_logo )"></span>
				<span>@</span>
				<span class="logo"><img :src="require( '@/assets/img/logos/' + game.hTeam_logo )"></span>
				<span>{{ game.hour }}</span>
			</div>

		</div></template>

	</container>

</template>

<script>

	import moment from 'moment'
	import Tools from '@/class/Tools.class'
	import device from '@/mixins/device'

	export default {
		
		name	: 'games',

		components: {
			container	: () => import( '@/components/container.vue' ),
		},

		mixins : [ device ],

		props	: [ 'games' ],

		data	: function() { return {

			'close_date'	: false,
			'close_hour'	: false,
			'teams'	: [],
			
			'vue_games'	: [],

		}},

		watch: {

			games() {

				var _this	= this



				this.games.forEach(function( game ) {

					var tmp_date	= moment( game.utc1_date )

					if (tmp_date.valueOf() < _this.close_date.valueOf() ) {
						_this.close_date	= tmp_date
					}

					var hTeam	= Tools.search_team( game.hteam_id )
					var vTeam	= Tools.search_team( game.vteam_id )

					_this.vue_games.push({
						game_id	: game.game_id,
						hTeam_logo	: hTeam.logo,
						vTeam_logo	: vTeam.logo,
						hour	: game.utc1_hour,
					})

				})

				this.close_hour	= this.close_date.format( 'HH' ) + 'H00'

			}

		},

		mounted	: function() {

			var close_moment = moment()

			close_moment.hours( 0 ).minutes( 0 ).seconds( 0 ).add( 1, 'd' )

			this.close_date	= close_moment
			this.close_hour	= close_moment.format( 'HH' ) + 'H' + close_moment.format( 'mm' )

		},

	}

</script>

<style lang="scss" scoped>

	@import '@/assets/scss/_base';



	/** ---------------------------------------*\
	*---------------------------------------- **/

	#games::v-deep {

		.container-head .head-actions {
			
			max-width: 40px;

			button {
				margin: 0;
			}
			
		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/
	
	#games {

		&::v-deep .container-head em {

			font-style: normal;

			&.red {
				color: $red;
			}

		}

		&::v-deep .container-body {
			padding: 0;
		}

		.games-list {

			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;
			align-items: center;
			padding-top: 12.5px;
			padding-bottom: 12.5px;

			@include mobile() {
				padding: 0;
				justify-content: space-between;
			}

		}

		.game {

			display: flex;
			justify-content: center;
			align-items: center;
			flex: calc( 100% / 6 );
			max-width: calc( 100% / 6 );
			margin: 12.5px 0;

			@include mobile() {
				flex: calc( 100% / 2 );
				max-width: calc( 100% / 2 );
			}

		}

		.game span {
			padding: 0 0.5em;
			font-weight: bold;
			font-size: 0.875em;
		}

	}

</style>
